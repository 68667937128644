import Config from '../config';

/**
 * Returns an articles list
 * @param {string} query the query filter (default = '')
 * @returns {Promise<array>} the articles list
 */
 export function getArticles(query = '') {
    // Get the articles list from Drirectus
    return fetch(`${Config.articles.url}/items/articles${query}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${Config.articles.token}`
        }
    })
    .then((response) => response.json()) // Parse the response into a JSON object
    .then((result) => result.data)
    .then((articles) => {
        const translatedArticles = [];
        articles.forEach((article) => {
            translatedArticles.push(getTranslations(article));
        });

        return Promise.all(translatedArticles);
    });
}

/**
 * Get all translations for a given article
 * @param {string} article the article object
 * @returns {Promise<Object>} the article with all the translations
 */
function getTranslations(article) {
    return fetch(`${Config.articles.url}/items/articles_translations?filter={"article_id": {"_eq": "${article.id}"}}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${Config.articles.token}`
        }
    })
    .then((response) => response.json())
    .then((result) => result.data)
    .then((translations) => {
        // Remove the translations IDs and replace them with the actual translation objects
        article.translations = [];
        translations.forEach((translation) => {
            article.translations[translation.language_code.split('-')[0]] = translation;
        });
        return Promise.resolve(article);
    });
}

/**
 * Returns a translated articles list
 * @param {string} locale the user locale (default = 'fr')
 * @param {string} query the query filter (default = '')
 * @returns {Promise<array>} the articles list
 */
export function getTranslatedArticles(locale = 'fr', query = '') {
    // Get the articles list from Drirectus
    return fetch(`${Config.articles.url}/items/articles${query}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${Config.articles.token}`
        }
    })
    .then((response) => response.json()) // Parse the response into a JSON object
    .then((articles) => {
        // Instanciate a return object with the articles list
        const ret = [articles.data];
        // For each article
        for (let i = 0; i < articles.data.length; i++) {
            // Add the translation to the return object
            ret.push(getTranslation(articles.data[i].id, locale));
        }

        // Wait for all promises to resolve and return the result
        return Promise.all(ret)
    })
    .then((result) => {
        // Loop through the result object and push the translations in a more comfortable way
        for (let i = 0; i < result[0].length; i++) {
            result[0][i].translation = result[i + 1];
        }

        // Return the article object with translations
        return Promise.resolve(result[0]);
    });
}

/**
 * Returns a specific translated article
 * @param {string} id the article ID
 * @returns {Promise<Object>} the article data
 */
 /*export function getArticle(id) {
    return fetch(`${Config.articles.url}/items/articles/${id}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${Config.articles.token}`
        }
    })
    .then((response) => response.json())
    .then((result) => {
        return Promise.resolve(result.data)
    });
}*/

/**
 * Returns a specific translated article
 * @param {string} id the article ID
 * @returns {Promise<Object>} the article data
 */
export function getTranslatedArticle(id) {
    return fetch(`${Config.articles.url}/items/articles/${id}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${Config.articles.token}`
        }
    })
    .then((response) => response.json())
    .then((result) => result.data)
    .then((article) => {
        return getTranslations(article);
    });
    /*.then((result) => {
        const ret = [result.data];
        ret.push(getTranslation(result.data.id, locale))
        return Promise.all(ret);
        //return Promise.resolve(result.data)
    })
    .then((result) => {
        result[0].translation = result[1];
        return Promise.resolve(result[0]);
    });*/
}

/**
 * Get an article from a translated slug
 * @param {string} slug the translated slug to look for 
 * @returns 
 */
export function getTranslatedArticleFromSlug(slug) {
    return getArticles()
    .then((articles) => {
        // Loop through all the articles
        for (let i = 0; i < articles.length; i++) {
            // Loop through all the article translations
            for (const lang in articles[i].translations) {
                // If slugs are matching
                if (articles[i].translations[lang].slug === slug) {
                    // Resolve the promise with the article
                    return Promise.resolve(articles[i]);
                }
            }
        }

        // If nothing was found, reject the promise with a slug-not-found error
        return Promise.reject('slug-not-found: ' + slug);
    });
}

/**
 * Returns a specific article translated part
 * @param {string} id the article ID
 * @param {string} locale the user locale
 * @returns {Promise<Object>} the article translated data
 */
export function getTranslation(id, locale) {
    return fetch(`${Config.articles.url}/items/articles_translations?filter={"article_id": {"_eq": "${id}"}}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${Config.articles.token}`
        }
    })
    .then((response) => response.json())
    .then((result) => {
        for (let i = 0; i < result.data.length; i++) {
            if (result.data[i].language_code.split('-')[0] === locale) {
                return Promise.resolve(result.data[i]);
            }
        }
    });
}

/**
 * Returns an image from ID
 * @param {string} id 
 * @returns {Promise} the image data
 */
export function getImage(id) {
    return fetch(`${Config.articles.url}/assets/${id}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${Config.articles.token}`
        }
    })
    .then((response) => response.blob()) // Make the response into a blob
    .then((blob) => {
        // Wrap the FileReader terrible callback API into a Promise
        return new Promise((resolve, reject) => {
            // Make the blob into a base64 data string
            const reader = new FileReader();
            reader.readAsDataURL(blob);

            reader.onloadend = function() {
                return resolve(reader.result);
            }

            reader.onerror = function() {
                return reject(reader.error);
            }
        });
    });
}