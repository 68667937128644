<template>
    <article>
        <!-- Complete article template -->
        <div v-if="!isLight">
            <header>
                <h2 class="title has-text-white has-text-weight-light is-size-1 is-size-2-mobile">{{ title }}</h2>
                <span class="has-text-white has-text-weight-light is-size-5 is-size-6-mobile" v-if="showHeading">{{ date }}</span>
                <img class="heading" :src="headingImg" alt="heading_image" v-if="showHeading" />
            </header>
            <article class="article-content">
                <h3 class="has-text-left title has-text-white is-size-4 is-size-5-mobile" v-html="header" v-if="showHeading"></h3>
                <div class="has-text-justified is-size-4-desktop is-size-5-tablet" v-html="content"></div>
            </article>
        </div>
        <!-- Article for list template -->
        <div v-if="isLight">
            <header>
                <img :src="headingImg" alt="heading" width="256" height="256" />
                <h2 class="title has-text-white has-text-weight-light is-size-3 is-size-4-mobile" :class="{'has-text-left': !isLight, 'has-text-center': isLight}">{{ title }}</h2>
            </header>
            <form-button type="button" @click="$router.push({path: getArticlePath})"
                cssClass="has-text-white has-background-primary is-rounded"
                :caption="$t('navigation.read_more')" />
        </div>
    </article>
</template>

<script>
import { bus } from '@/main';

import { getTranslatedArticle, getTranslatedArticleFromSlug, getImage } from '@/scripts/articles';

import FormButton from '@/components/layout/controls/form/FormButton.vue';

/**
 * Article component for displaying a Directus article
 * @author Alba IT
 * @version 1.0.0
 */
export default {
    name: 'DirectusArticle',
    components: {
        FormButton
    },
    props: {
        /**
         * Display the article Heading
         */
        showHeading: {
            type: Boolean,
            default: true
        },
        /**
         * Display in light mode (only disply the article first this.capLength characters)
         */
        isLight: {
            type: Boolean,
            default: false
        },
        /**
         * The article caption length for light mode
         */
        capLength: {
            type: Number,
            default: 255
        },
        /**
         * The article ID
         */
        /*id: {
            type: [Number, String],
            required: true
        }*/
        /**
         * Getter type, 'id' or 'slug'
         */
        from: {
            type: String,
            default: 'id'
        },
        /**
         * The article getter, ID or translated slug
         */
        getter: {
            type: [Number, String],
            required: true
        }
    },
    data: function() {
        return {
            type: undefined,
            date: undefined,
            heading: undefined,
            headingImg: undefined,
            header: undefined,
            title:undefined,
            content: undefined
        };
    },
    computed: {
        getArticlePath: function() {
            return `/${this.$i18n.locale}/${this.$t("message.common.article") }/${this.getter}`;
        }
    },
    mounted: function() {
        // Load the article from Directus
        this.loadArticle();
        // React to locale changes
        bus.$on('locale-changed', () => {
            this.loadArticle();
        });
    },
    methods: {
        loadArticle: function() {
            // Init the loader function according to this.from
            const loader = (this.from === 'id') ? getTranslatedArticle : getTranslatedArticleFromSlug;

            // Load the article
            loader(this.getter)
            .then(async (result) => {
                this.type = result.article_type_id;
                this.date = result.date.split("T")[0];
                this.heading = result.heading;
                this.header = result.translations[this.$i18n.locale].header;
                this.title = result.translations[this.$i18n.locale].title;
                this.content = result.translations[this.$i18n.locale].content;

                if (this.header !== null) {
                    // Load the article heading image
                    this.headingImg = await getImage(this.heading);
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/variables.scss';

::v-deep h2 {
    color: $font-primary-color;
    font-size: 3rem;
    font-weight: 400;
    margin-bottom: 1.5rem;
    line-height: 1.125;
}
::v-deep h3 {
    color: $font-primary-color;
    font-size: 2rem;
    font-weight: 400;
    margin-bottom: 1.5rem;
    line-height: 1.125;
    text-align: left;
}

@media(max-width: $tablet-landscape) {
    ::v-deep h2 {
        font-size: 2rem;
        margin-bottom: 1.5rem;
        line-height: 1.125;
    }
    ::v-deep h3 {
        font-size: 1.5rem;
        margin-bottom: 1.5rem;
        line-height: 1.125;
    }
}

.heading {
    width: 100%;
    max-height: 600px;
    object-fit: cover;
}
.article-content {
    width: 100%;
    max-width: 1040px;
    margin: auto;

    ::v-deep p { // ::v-deep is the >>> deep selector alias, parsed incorrectly ba SaSS
        margin: 0 0 1.5em 0;

        strong {
            color: $font-secondary-color;
        }
    }
}
</style>